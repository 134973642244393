import { Container } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import Grid from '@mui/material/Grid'; // Grid version 1

const bionicTextForAnimations = (word, bold) => {
    const midPoint = Math.ceil(word.length / 2);
    const firstHalf = word.slice(0, midPoint);
    const secondHalf = word.slice(midPoint);
  
    if (bold) {
      return `<strong>${firstHalf}</strong>${secondHalf}`;
    } else {
      return `${firstHalf}${secondHalf}`;
    }
  };
  
  
  const useBionicAnimation = (text, delay = 0.01) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [animatedText, setAnimatedText] = useState(text.map((word) => bionicTextForAnimations(word, false)).join(" "));
  
    useEffect(() => {
      if (currentIndex < text.length) {
        const timer = setTimeout(() => {
          const newText = animatedText.split(" ").map((word, index) => {
            if (index === currentIndex) {
              return bionicTextForAnimations(text[currentIndex], true);
            }
            return word;
          });
          setAnimatedText(newText.join(" "));
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }, delay);
        return () => clearTimeout(timer);
      }
    }, [currentIndex, text, delay, animatedText]);
  
    return animatedText;
  };

const HomePage = () => {
    const h1Text = "Read faster with an actual focus on the text";
    const h2Text = "Studies show that the capability to focus on a longer text is declining around the world. This free tool helps you regain that focus you need, whether it’s for work, school or personal reading.";
    const h3Text = "FocusedReading.io turns any text to a bionic text. Multiple studies have showed even diagnosed ADHD sufferers reading twice as fast when the first part of every word is bolded. The same helps anyone to read faster and focus more on the text.";

    const animatedH1 = useBionicAnimation(h1Text.split(" "), 50);
    const animatedH2 = useBionicAnimation(h2Text.split(" "), 10);
    const animatedH3 = useBionicAnimation(h3Text.split(" "), 10);

return (
    <div>
        
    <Container maxWidth="lg">
    <div class="first-fold-container">
        <div class="first-fold-inner">
            
            <div className="content-div">
            <div class="hero">
                <h1 dangerouslySetInnerHTML={{ __html: animatedH1 }}></h1>
                <h3 dangerouslySetInnerHTML={{ __html: animatedH2 }}></h3>
                <h5 dangerouslySetInnerHTML={{ __html: animatedH3 }}></h5>
            </div>
        </div>
        </div>
    </div>
    <div class="main-content">
        <div class="main-content-container">
        <div class="highlights-bar">
            <h3>FREE</h3>
            <p>
                <b>Infinite divoring is and will stay free as everyone deserves equal opportunity</b>
            </p>
        </div>
        <Grid container spacing={2} className="content-row">
            <Grid  className="text-container" xs={12} md={8}>
                <h2>divoring pages for adults help you relax and relieve stress</h2>
                <p>
                    divoring pages used to for kids, but that's no more. 
                    People of all ages can and should enjoy the benefits of divoring. 
                </p>
                <p>
                    Studies show that divoring books are a great way to quiet your mind and relax both body and brain. When you are divoring you'll focus on that paper infront of you.
                    This can be therapeutic and induce meditative state. 
                </p>
            </Grid>
            <Grid className="picture-container" xs={12} md={4}>
                <img class="content-div-img" src={require('./assets/rocks.jpg')} />
            </Grid>
        </Grid>

        <div class="highlights-bar-list">
            <div class="highlights-bar-item">
                <h4>Read and internalize faster</h4>
            </div>
            <div class="highlights-bar-item">
                <h4>Improve focus and remember what you read</h4>
            </div>
            <div class="highlights-bar-item">
                <h4>Concentrate even with Attention Deficit Hyperactivity Disorder(ADHD)</h4>
            </div>
        </div>
        <Grid container spacing={2} className="content-row">
            <Grid  className="text-container" xs={12} md={8}>
                <h2>divoring pages for adults help you relax and relieve stress</h2>
                <p>
                    divoring pages used to for kids, but that's no more. 
                    People of all ages can and should enjoy the benefits of divoring. 
                </p>
                <p>
                    Studies show that divoring books are a great way to quiet your mind and relax both body and brain. When you are divoring you'll focus on that paper infront of you.
                    This can be therapeutic and induce meditative state. 
                </p>
            </Grid>
            <Grid className="picture-container" xs={12} md={4}>
                <img class="content-div-img" src={require('./assets/rocks.jpg')} />
            </Grid>
        </Grid>
       
        
        <div class="cta-bar">
            <div class="highlights-bar-tem">
                <button type="default">Read more about divoring</button>        
            </div>
            <div class="highlights-bar-tem">
                <button type="primary">Get started</button>        
            </div>
        </div>
            
        </div>
    </div>
    </Container>
    </div>
);
}
export default HomePage;