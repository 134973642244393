import React from "react"
import { Routes ,Route, Link } from 'react-router-dom';
// We will create these two pages in a moment
import HomePage from "./HomePage"
import Converter from "./Converter"
import logo from './logo.svg' // relative path to image 


export default function App() {
  const {  useState  } = React;
 
  const [current, setCurrent] = useState('mail');
  const onClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };
/*
<Menu.Item key="2">
            <Link to="/fractal" className="nav-text">Fractals</Link>
        </Menu.Item>
        <Menu.Item key="3">
            <Link to="/picture" className="nav-text">Picture converter</Link>
        </Menu.Item>


          <Route path="/fractal" element={<FractalGenerator/>} />
          <Route path="/picture" element={<PictureConverter/>} />
  */
  return (
    <div>
      
      <div class="navbar">
        <div class="nav-left"><img src={logo} />
          <a class="top-nav-link" href="/">Home</a>
          <a class="top-nav-link" href="/read">Speed Reader</a>
        </div>
        <div class="nav-right">
          <a class="buycoffee-btn" href="https://www.buymeacoffee.com/FocusedReading" target="_blank" rel="noopener noreferrer">Buy me a coffee</a>
        </div>
      </div>

      <Routes>
        <Route exact path="/" element={<HomePage/>} />
        <Route path="/read" element={<Converter/>} />
      </Routes>

      <div class="footer">
        <h4>Enjoy FocusedReading.io?</h4>
        <p>Support the maintenance and development</p>
        <a class="buycoffee-btn" href="https://www.buymeacoffee.com/FocusedReading" target="_blank" rel="noopener noreferrer">Buy me a coffee</a>

      </div>
    </div>
  )
}