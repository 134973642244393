import React, { useState, useRef, useEffect } from "react";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "./App.css";
import Button, { buttonClasses } from '@mui/base/Button';
import { styled } from '@mui/system';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import logo from './logo.svg' // relative path to image 
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Document, Packer, Paragraph, TextRun } from 'docx';



interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const generateWordDocument = async (bionicText) => {
  const doc = new Document();

  bionicText.forEach((line) => {
    const paragraph = new Paragraph();

    line.forEach((word) => {
      const firstHalf = new TextRun(word.firstHalf).bold();
      const secondHalf = new TextRun(word.secondHalf);
      const space = new TextRun(' ');

      paragraph.addRun(firstHalf);
      paragraph.addRun(secondHalf);
      paragraph.addRun(space);
    });

    doc.addParagraph(paragraph);
  });

  const buffer = await Packer.toBuffer(doc);
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = 'bionic-text.docx';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};



pdfMake.vfs = pdfFonts.pdfMake.vfs;
GlobalWorkerOptions.workerSrc = "//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.5.141/pdf.worker.min.js";

const bionicText = (word) => {
  console.log(word)
  if (!word) return "";
  if (word === "\n") return "<br>"; // Replace newline characters with <br> tags
  if (!/^\w+$/.test(word)) return word; // Return the non-word character as-is

  const midPoint = Math.ceil(word.length / 2);
  const firstHalf = word.slice(0, midPoint);
  const secondHalf = word.slice(midPoint);

  return `<strong>${firstHalf}</strong>${secondHalf}`;
};

const bionicTextForAnimations = (word, bold) => {
  const midPoint = Math.ceil(word.length / 2);
  const firstHalf = word.slice(0, midPoint);
  const secondHalf = word.slice(midPoint);

  if (bold) {
    return `<strong>${firstHalf}</strong>${secondHalf}`;
  } else {
    return `${firstHalf}${secondHalf}`;
  }
};


const useBionicAnimation = (text, delay = 0.01) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animatedText, setAnimatedText] = useState(text.map((word) => bionicTextForAnimations(word, false)).join(" "));

  useEffect(() => {
    if (currentIndex < text.length) {
      const timer = setTimeout(() => {
        const newText = animatedText.split(" ").map((word, index) => {
          if (index === currentIndex) {
            return bionicTextForAnimations(text[currentIndex], true);
          }
          return word;
        });
        setAnimatedText(newText.join(" "));
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);
      return () => clearTimeout(timer);
    }
  }, [currentIndex, text, delay, animatedText]);

  return animatedText;
};


const Converter = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pastedText, setPastedText] = useState("");
  const fileInputRef = useRef();
  const [processedText, setProcessedText] = useState([]);
  const [pointerOption, setPointerOption] = useState("enlarge");
  const [result, setResult] = useState("");

  const [resultTabValue, setResultTabValue] = React.useState(0);
  const [inputTabValue, setInputTabValue] = React.useState(0);

  const [fontFamily, setFontFamily] = useState("Arial");
  const [fontSize, setFontSize] = useState(16);
  const [lineHeight, setLineHeight] = useState(1.5);

  const [conversionDone, setConversionDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);



  const handleResultTabChange = (event, newValue) => {
    setResultTabValue(newValue);
  };
  const handleInputTabChange = (event, newValue) => {
    setInputTabValue(newValue);
  };
  const h1Text = "Read faster with an actual focus on the text";
  const h2Text = "Studies show that the capability to focus on a longer text is declining around the world. This free tool helps you regain that focus you need, whether it’s for work, school or personal reading.";
  const h3Text = "FocusedReading.io turns any text to a bionic text. Multiple studies have showed even diagnosed ADHD sufferers reading twice as fast when the first part of every word is bolded. The same helps anyone to read faster and focus more on the text.";

  const animatedH1 = useBionicAnimation(h1Text.split(" "), 50);
  const animatedH2 = useBionicAnimation(h2Text.split(" "), 10);
  const animatedH3 = useBionicAnimation(h3Text.split(" "), 10);

  const [isFullScreen, setIsFullScreen] = useState(false);


  const blue = {
    500: '#D9D6C1',
    600: '#C3C0A8',
    700: '#0059B2',
  };

  const grey = {
    100: '#eaeef2',
    300: '#afb8c1',
    900: '#24292f',
  };



  const CustomButton = styled(Button)(
    ({ theme }) => `
    font-family: IBM Plex Sans, sans-serif;
    font-weight: bold;
    font-size: 0.875rem;
    background-color: ${blue[500]};
    padding: 10px 28px;
    margin-right: 10px;
    border-radius: 3px;
    color: #3D4B3B;
    transition: all 150ms ease;
    cursor: pointer;
    border: none;
    box-shadow: 0px 4px 4px #C0C0C0;
  
    &:hover {
      background-color: ${blue[600]};
    }
  
    &.${buttonClasses.active} {
      box-shadow: 0px 5px 8px #C0C0C0;
    }
  
    &.${buttonClasses.focusVisible} {
      box-shadow: 0 3px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
      outline: none;
    }
  
    &.${buttonClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
    `,
  );

  const handleTextAreaChange = (text) => {
    try {

      setConversionDone(false); // Add this line
      setIsLoading(true);
      console.log(isLoading)
      const inputText = text;
      const bionicResult = processText(inputText);
      console.log(bionicResult[1])
      setResult(bionicResult);
      setTimeout(() => {
        setIsLoading(false);
        setConversionDone(true); // Add this line
      }, 3000);
  
      
    } catch (error) {
      console.error(error);
    } finally {
    }

    
  };

  const CustomButtonSecondary = styled(Button)(
    ({ theme }) => `
    font-family: IBM Plex Sans, sans-serif;
    font-weight: bold;
    font-size: 0.875rem;
    background-color: #AAC8A7;
    padding: 12px 24px;
    margin-right: 10px;
    border-radius: 12px;
    color: #0b4d00;
    transition: all 150ms ease;
    cursor: pointer;
    border: none;
    box-shadow: 0px 4px 3px #a2a2a2;
  
    &:hover {
      background-color: #9BB798;
    }
  
    &.${buttonClasses.active} {
      background-color: #9BB798;
    }
  
    &.${buttonClasses.focusVisible} {
      box-shadow: 0 3px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
      outline: none;
    }
  
    &.${buttonClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
    `,
  );
  const [wordIndex, setWordIndex] = useState(0);
  const [isReading, setIsReading] = useState(false);



  const startReading = () => {
    setWordIndex(0);
    setIsReading(true);
  };

  const updatePointerOption = (event) => {
    setPointerOption(event.target.value);
  };

  useEffect(() => {
    if (isReading && wordIndex < result.length - 3) {
      const timer = setTimeout(() => {
        setWordIndex((prevWordIndex) => prevWordIndex + 3);
      }, 300); // Adjust the reading speed (in milliseconds) here.
      return () => clearTimeout(timer);
    } else if (wordIndex >= result.length - 3) {
      setIsReading(false);
    }
  }, [isReading, wordIndex, result]);


  const processPDF = async (file) => {
    try {
      const fileReader = new FileReader();
      fileReader.onload = async (event) => {
        const arrayBuffer = event.target.result;
        const pdf = await getDocument({ data: arrayBuffer }).promise;
        const numPages = pdf.numPages;
        const content = [];

        for (let i = 1; i <= numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          const pageText = textContent.items.map((item) => item.str).join(" ");
          content.push({ text: bionicText(pageText), style: "paragraph" });
        }

        const docDefinition = {
          content: content.map((item) => ({
            stack: [
              {
                columns: item.text.map((segment) => ({
                  text: segment.text,
                  bold: segment.bold,
                  noWrap: true,
                })),
                columnGap: 0,
              },
            ],
            style: "paragraph",
          })),
          styles: {
            paragraph: {
              fontSize: 12,
              lineHeight: 1.2,
            },
          },
        };

        pdfMake.createPdf(docDefinition).download("bionic-reading.pdf");
      };
      fileReader.readAsArrayBuffer(file);
    } catch (error) {
      console.error("Error processing PDF:", error);
    }
  };

  const processInputText = (inputText) => {
    const words = inputText.split(/\s+/);
    return words.flatMap((word, index) => {
      const midPoint = Math.ceil(word.length / 2);
      const firstHalf = word.slice(0, midPoint);
      const secondHalf = word.slice(midPoint);
      return [
        { text: firstHalf, bold: true },
        { text: secondHalf, bold: false },
        { text: index < words.length - 1 ? " " : "", bold: false },
      ];
    });
  };




  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      processPDF(file);
    }
  };

  const processText = (inputText) => {
    const regex = /(\w+|[\W\s])/g; // Updated regex to include line breaks
    const wordsAndNonWords = inputText.match(regex);
    console.log(wordsAndNonWords)

    if (!wordsAndNonWords) return ""; // Return an empty string if there are no matches

    return wordsAndNonWords.map((word) => bionicText(word)).join("");
  };



  const processTextPage = async (text) => {
    setLoading(true);

    const processed = processText(text);
    console.log(processed)
    setResult(processed);
    setLoading(false);
  };





  return (

    <div>
      <Container maxWidth="lg">
        <div class="hero">
          <h1 dangerouslySetInnerHTML={{ __html: animatedH1 }}></h1>
        </div>
        <div className="App">
          <div class="card">
            <div className="card-top-nav">
              <button className="back-button" onClick={() => setConversionDone(false)}></button>
            </div>
            {isLoading ? (
              <p>Loading...</p>
            ) : conversionDone ? (
              <div className="result-text">
                {/* Render the bionic text here */}
                <p>1</p>
              </div>
            ) : (
              <div className="input-box">
                {/* Render the input box here */}
                <p>2</p>
              </div>
            )}

            <Tabs value={inputTabValue} onChange={handleInputTabChange} aria-label="basic tabs example">
              <Tab label="Text" {...a11yProps(0)} />
              <Tab label="File" {...a11yProps(1)} />
              <Tab label="Website link" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={inputTabValue} index={0} className="card-tab">
              <textarea
                className="input-textarea"
                rows="30"
                placeholder="Input the text you aim to read here"
                value={pastedText}
                onChange={(e) => setPastedText(e.target.value)}
                style={{ display: "block", marginBottom: "1rem" }}
              />
              <CustomButton onClick={() => handleTextAreaChange(pastedText)}>Convert</CustomButton>
            </TabPanel>
            <TabPanel value={inputTabValue} index={1} className="card-tab">
              2
            </TabPanel>
            <TabPanel value={inputTabValue} index={2} className="card-tab">
              3
            </TabPanel>


          </div>

          <div class="vertical-divider">

          </div>

          <div class="card">
            <Tabs value={resultTabValue} onChange={handleResultTabChange} aria-label="basic tabs example">
              <Tab label="Result" {...a11yProps(0)} />
              <Tab label="Word by Word Speed Reading" {...a11yProps(1)} />
              <Tab label="Export" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={resultTabValue} index={0} className="card-tab">
            <div>
              <label htmlFor="font-family">Font:</label>
              <select
                id="font-family"
                value={fontFamily}
                onChange={(e) => setFontFamily(e.target.value)}
              >
                <option value="Arial">Arial</option>
                <option value="Verdana">Verdana</option>
                <option value="Times New Roman">Times New Roman</option>
                {/* Add more fonts as needed */}
              </select>
            </div>
            <div>
              <label htmlFor="font-size">Font Size:</label>
              <select
                id="font-size"
                value={fontSize}
                onChange={(e) => setFontSize(e.target.value)}
              >
                <option value="12">12</option>
                <option value="14">14</option>
                <option value="16">16</option>
                {/* Add more font sizes as needed */}
              </select>
            </div>
            <div>
              <label htmlFor="line-height">Line Height:</label>
              <select
                id="line-height"
                value={lineHeight}
                onChange={(e) => setLineHeight(e.target.value)}
              >
                <option value="1">1</option>
                <option value="1.2">1.2</option>
                <option value="1.5">1.5</option>
                {/* Add more line heights as needed */}
              </select>
            </div>

              <div style={{ marginTop: "2rem", whiteSpace: "pre-wrap" }} class="resulting-text">
                <div className="bionic-text-container" style={{
                  fontFamily: fontFamily,
                  fontSize: `${fontSize}px`,
                  lineHeight: lineHeight,
                }} 
                dangerouslySetInnerHTML={{ __html: result }}></div>
              </div>
              <div id="result-actions">
                <div style={{ marginTop: "1rem" }}>
                  <label htmlFor="pointer-option">Pointer option:</label>
                  <select
                    name="pointer-option"
                    id="pointer-option"
                    value={pointerOption}
                    onChange={updatePointerOption}
                  >
                    <option value="enlarge">Enlarge</option>
                    <option value="underline">Underline</option>
                    <option value="color">Change color</option>
                  </select>
                </div>

                <button onClick={startReading} disabled={isReading}>
                  Start Reading
                </button>
                <div
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    marginTop: "2rem",
                    minHeight: "30px",
                  }}
                >
                  {processedText[wordIndex]?.text}
                  <span style={{ fontWeight: "normal" }}>
                    {processedText[wordIndex + 1]?.text}
                  </span>
                </div>
                <CustomButton onClick={() => setIsFullScreen(!isFullScreen)}>Fullscreen reading mode</CustomButton>
              </div>

            </TabPanel>
            <TabPanel value={resultTabValue} index={1} className="card-tab">
            </TabPanel>
            <TabPanel value={resultTabValue} index={2} className="card-tab">
              <CustomButtonSecondary onClick={() => processText(pastedText)}>Download PDF</CustomButtonSecondary>
            </TabPanel>

            {isFullScreen && (
              <div
                className="full-screen-modal"

              >
                <div class="modal-nav">
                  <CustomButtonSecondary onClick={(e) => {
                    if (e.target === e.currentTarget) {
                      setIsFullScreen(false);
                    }
                  }}>close</CustomButtonSecondary>
                </div>

                <div style={{ marginTop: "2rem", whiteSpace: "pre-wrap" }} class="reading-modal">
                  <div className="bionic-text-container" dangerouslySetInnerHTML={{ __html: result }}></div>
                </div>

              </div>
            )}
          </div>

        </div>

      </Container>
    </div>
  );
}
/*
<div style={{ marginTop: "1rem" }}>
  <label htmlFor="pointer-option">Pointer option:</label>
  <select
    name="pointer-option"
    id="pointer-option"
    value={pointerOption}
    onChange={updatePointerOption}
  >
    <option value="enlarge">Enlarge</option>
    <option value="underline">Underline</option>
    <option value="color">Change color</option>
  </select>
</div>

<button onClick={startReading} disabled={isReading}>
  Start Reading
</button>
<div
  style={{
    fontSize: "24px",
    fontWeight: "bold",
    marginTop: "2rem",
    minHeight: "30px",
  }}
>
  {processedText[wordIndex]?.text}
  <span style={{ fontWeight: "normal" }}>
    {processedText[wordIndex + 1]?.text}
  </span>
</div>


*/
export default Converter;
